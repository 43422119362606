import * as React from 'react'

function SvgHoverClose() {
	return (
		<svg width={31} height={31} xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fillRule="evenodd">
				<circle fill="#F5F5F5" cx={15.5} cy={15.5} r={15.5} />
				<path
					d="M9.49 21.51L21.51 9.49m0 12.02L9.49 9.49"
					stroke="#505050"
					strokeLinecap="round"
					strokeWidth={2}
				/>
			</g>
		</svg>
	)
}

export default SvgHoverClose

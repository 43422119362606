/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { LightboxSource } from '@mangoweb/fslightbox'
import Image from 'next/image'
import React from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import s from './Gallery.module.sass'

export function Gallery({ images }: { images: ImageResult[] }) {
	const sources: LightboxSource[] = React.useMemo(() => {
		return images.map((image) => {
			return {
				source: image?.url ?? '',
				type: 'image',
			}
		})
	}, [images])

	const [gallery, setGallery] = React.useState<null | { open: (i: number) => void }>(null)
	React.useEffect(() => {
		import('@mangoweb/fslightbox').then((module) => {
			setGallery(module.lightbox(sources))
		})
	}, [sources])

	return (
		<div className={s.Gallery}>
			{images.map((image, i) => (
				<div key={image.id} className={s.ImageBox}>
					<div
						className={s.Image}
						onClick={() => {
							gallery?.open(i)
						}}>
						{image.url && <Image alt="" src={image.url} layout="fill" objectFit="cover" />}
					</div>
				</div>
			))}
		</div>
	)
}

import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import s from './Popups.module.sass'

type PropupsContext = {
	activePopup: null | string
	activeId: null | string
	portalRef?: null | HTMLDivElement
	activePlace: string | null
	openPopup: (popup: string, id: string, place: string) => void
	closePopup: (place: string) => void
	popups: Record<string, React.ReactNode>
}

export const PopupsContext = React.createContext<null | PropupsContext>(null)

export function PopupsProvider(props: {
	children: React.ReactNode
	popups: Record<string, React.ReactNode>
}) {
	const [ref, setRef] = React.useState<null | HTMLDivElement>(null)
	const { popups } = props
	const [activePlace, setActivePlace] = React.useState<string | null>(null)
	const [activePopup, setActivePopup] = React.useState<string | null>(null)
	const [activeId, setActiveId] = React.useState<string | null>(null)

	const context = React.useMemo<PropupsContext>(() => {
		return {
			activePopup,
			activeId,
			activePlace,
			portalRef: ref,
			openPopup(popup: string, id: string, place: string) {
				if (!activePopup || place === 'click') {
					setActivePopup(popup)
					setActiveId(id)
					setActivePlace(place)
				}
			},
			closePopup(place: string) {
				if (place === activePlace || place === 'click') {
					setActivePopup(null)
					setActiveId(null)
					setActivePlace(null)
				}
			},
			popups,
		}
	}, [activeId, activePlace, activePopup, popups, ref])

	return (
		<PopupsContext.Provider value={context}>
			{props.children}
			<div ref={setRef}></div>
		</PopupsContext.Provider>
	)
}

export function PopupOpener(props: { popup: string; id: string; children: React.ReactNode }) {
	const context = React.useContext(PopupsContext)

	const isActive = props.id === context?.activeId && context?.activePopup === props.popup

	const isClicked = context?.activePlace === 'click'

	const handleClick: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
		(e) => {
			e.preventDefault()
			if (isActive && isClicked) {
				context?.closePopup('click')
			} else {
				context?.openPopup(props.popup, props.id, 'click')
			}
		},
		[isActive, isClicked, context, props.popup, props.id]
	)
	const [referenceElement, setReferenceElement] = useState<null | HTMLElement>(null)
	const [popperElement, setPopperElement] = useState<null | HTMLDivElement>(null)
	const [arrowElement, setArrowElement] = useState<null | HTMLDivElement>(null)
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
	})

	return (
		<>
			<a
				ref={setReferenceElement}
				href={`#${props.id}`}
				onClick={handleClick}
				onMouseEnter={() => {
					context?.openPopup(props.popup, props.id, 'hover')
				}}
				onMouseLeave={() => {
					context?.closePopup('hover')
				}}>
				{props.children}
			</a>
			{context?.portalRef &&
				props.id === context?.activeId &&
				context?.activePopup === props.popup &&
				ReactDOM.createPortal(
					<div
						ref={setPopperElement}
						className={s.Popup}
						style={styles.popper}
						{...attributes.popper}>
						{context.popups[props.popup]}
						<div ref={setArrowElement} className={s.Arrow} style={styles.arrow} />
					</div>,
					context.portalRef
				)}
		</>
	)
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import React, { useState } from 'react'
import type { SideMenuHistoryPeriodResult } from '../data/content/SideMenuHistoryPeriodFragment'
import SvgClose from '../svg/sprites/Close'
import SvgHamburger from '../svg/sprites/Hamburger'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Link } from './Link'
import s from './SideMenu.module.sass'

export type SideMenuProps = {
	items: SideMenuHistoryPeriodResult[]
}

export function SideMenu(props: SideMenuProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()

	React.useEffect(() => {
		setOpen(false)
	}, [loading])
	return (
		<div className={clsx(s.SideMenu, open && s.isOpen)}>
			<div className={s.Links}>
				{props.items.map((item, i) => (
					<div key={i}>
						{item.slug && (
							<Link href={item.slug} nonExternal className={s.Link} activeClassName={s.isActive}>
								{item.cover?.title}
							</Link>
						)}
					</div>
				))}
			</div>
			<div className={s.Mobile}>
				<button
					className={s.MobileBtn}
					type={'button'}
					onClick={() => {
						setOpen((old) => !old)
					}}>
					<span className={s.svgOpen}>
						<SvgHamburger />
					</span>
					<span className={s.svgClose}>
						<SvgClose />
					</span>
					<p className={s.ActiveLink}>MENU</p>
					<p className={clsx(s.ActiveLink, s.MenuClose)}>ZAVŘI</p>
				</button>
			</div>
		</div>
	)
}

import * as React from 'react'

function SvgInstagram() {
	return (
		<svg viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
			<g transform="translate(.515 .9)" fill="none">
				<rect fill="#4A799F" width={39} height={40} rx={3} />
				<path
					d="M24.608 17.967H29v9.318a2.713 2.713 0 01-2.712 2.716H12.712A2.716 2.716 0 0110 27.285v-9.318h4.392a5.7 5.7 0 1010.216 0zM15.252 16.7h-5.251v-3.889c0-1.001.812-1.811 1.813-1.811h15.374c1.003 0 1.813.811 1.813 1.811V16.7H23.75a5.686 5.686 0 00-4.249-1.9 5.688 5.688 0 00-4.249 1.9zm-.818-5.7v3.8h.633V11h-.633zm-1.267 0v3.8h.633V11h-.633zM11.9 11v3.8h.633V11H11.9zm12.487 1.267a.45.45 0 00-.454.454v2.259a.45.45 0 00.454.454h2.259a.45.45 0 00.454-.454v-2.259a.45.45 0 00-.454-.454h-2.259zM19.5 24.934a4.433 4.433 0 100-8.867 4.433 4.433 0 000 8.867z"
					fill="#fff"
				/>
			</g>
		</svg>
	)
}

export default SvgInstagram

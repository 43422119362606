/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { decode } from 'html-entities'
import Image from 'next/image'
import type React from 'react'
import { FunctionComponent, useRef } from 'react'
import type { ContentReferenceType } from '../../generated/content'
import type { ContentResult } from '../data/content/ContentFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import s from './ContentRenderer.module.sass'
import { Gallery } from './Gallery'
import { PopupOpener } from './Popups'

export type ContentRendererProps = { omitStyles?: boolean } & (
	| {
			content: ContentResult
	  }
	| { source: string }
)

type Block = ReferenceRendererProps<ContentResult['parts'][number]['references'][number]>

type ReferenceRenderers = Record<ContentReferenceType, (context: Block) => React.ReactNode>

const referenceRenderers: ReferenceRenderers = {
	image({ reference }) {
		return (
			reference.image?.image && (
				<div className={s.Block}>
					<Image
						alt=""
						src={reference.image.image.url}
						width={reference.image.image.width}
						height={reference.image.image.height}
					/>
					<p className={s.ImageDesc}>
						{reference.image.title ? reference.image.title : reference.image.image.title}
						{reference.image.image.author ? ', Autor: ' + reference.image.image.author : ''}
					</p>
				</div>
			)
		)
	},
	audio() {
		return <>audio</>
	},
	podcast() {
		return <>podcast</>
	},
	quote({ reference }) {
		return (
			<div className={s.Block}>
				<div className={s.Quote}>
					<span className={s.Text}>
						{reference.primaryText && (
							<RichTextRenderer source={decode(reference.primaryText, { level: 'html5' })} />
						)}
					</span>
				</div>
			</div>
		)
	},
	big_link() {
		return <>big_link</>
	},
	block_link() {
		return <>block_link</>
	},
	// photo_gallery() {
	// 	return <>photo_gallery</>
	// },
	photo_gallery({ reference }) {
		return (
			<Gallery
				images={reference.images.map((item) => item.image?.image).filter(filterNonEmpty) ?? []}
			/>
		)
	},
	photo_grid() {
		return <>photo_grid</>
	},
	embed() {
		return <>embed</>
	},
	html_code() {
		return <>html_code</>
	},
	listicle() {
		return <>listicle</>
	},
	info_box() {
		return <></>
	},
	poll_answer() {
		return <>poll_answer</>
	},
	respekt() {
		return <>respekt</>
	},
	despekt() {
		return <>despekt</>
	},
	subnavigation() {
		return <>subnavigation</>
	},
	promobox() {
		return <>promobox</>
	},
	faq() {
		return <>faq</>
	},
	awards() {
		return <>awards</>
	},
	reviews() {
		return <>reviews</>
	},
	feature() {
		return <>feature</>
	},
	link() {
		return <>link</>
	},
	subscription_form() {
		return <>subscription_form</>
	},
	banner() {
		return <>banner</>
	},
	image_with_text() {
		return <>image_with_text</>
	},
	download_button() {
		return <>download_button</>
	},
	text_with_icons() {
		return <>text_with_icons</>
	},
	buttons() {
		return <>buttons</>
	},
	slides() {
		return <>slides</>
	},
	shared_content() {
		return <>shared_content</>
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = function ContentRenderer(
	props
) {
	const richTextProps =
		'source' in props
			? { source: props.source }
			: { blocks: props.content.parts, sourceField: 'json' }
	const firstParagraphRendered = useRef(false)
	firstParagraphRendered.current = false

	const styles = props.omitStyles ? {} : s

	return (
		<div className={styles.Content}>
			<RichTextRenderer
				{...richTextProps}
				renderLeaf={(leaf) => {
					if (typeof leaf.children === 'string') {
						const match = leaf.children.split(/\{([^\*]+)\*([^\}]+)\}/gi)
						const result: React.ReactNode[] = []

						while (match.length) {
							const basic = match.shift()
							const text = match.shift()
							const key = match.shift()
							result.push(basic)
							if (typeof text === 'string' && typeof key === 'string') {
								result.push(
									<PopupOpener popup={key} id={String(match.length)}>
										{text}
									</PopupOpener>
								)
							}
						}
						if (result.length > 1) {
							return <>{result}</>
						}
						return leaf.fallback
					}
					return leaf.fallback
				}}
				renderElement={(el) => {
					const unwrappedElements: Array<typeof el['element']['type']> = [
						'anchor',
						'horizontalRule',
						'listItem',
						'reference',
						'scrollTarget',
						'tableCell',
						'tableRow',
					]
					if ((el.element.type as string) === 'question') {
						el = { ...el, fallback: <h2>{el.children}</h2> }
					}
					if (unwrappedElements.indexOf(el.element.type) > -1) {
						return el.fallback
					}
					if (el.element.type === 'paragraph' && !firstParagraphRendered.current) {
						firstParagraphRendered.current = true
						return <div className={clsx(styles.Block, styles.first)}>{el.fallback}</div>
					}
					return <div className={clsx(styles.Block)}>{el.fallback}</div>
				}}
				referenceRenderers={referenceRenderers}
			/>
		</div>
	)
}

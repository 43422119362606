import * as React from 'react'

function SvgFacebook() {
	return (
		<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
			<g fill="none">
				<rect width={40} height={40} rx={3} fill="#405E93" />
				<path
					d="M17.55 30v-9.134H14v-3.551h3.55v-2.709c0-2.84 2.03-4.606 5.229-4.606 1.925 0 3.221.131 3.221.131v3.202h-2.169c-1.375 0-2.02.419-2.02 1.683v2.299h4.047l-.521 3.551H21.81V30h-4.26z"
					fill="#fff"
				/>
			</g>
		</svg>
	)
}

export default SvgFacebook

import { SocialLinkType } from '../../generated/content'
import SvgFacebook from '../svg/sprites/Facebook'
import SvgInstagram from '../svg/sprites/Instagram'
import SvgTwitter from '../svg/sprites/Twitter'
import s from './FooterSocialIcon.module.sass'

const socialIcons = {
	[SocialLinkType.facebook]: (
		<span className={s.Icons}>
			<SvgFacebook />
		</span>
	),
	[SocialLinkType.instagram]: (
		<span className={s.Icons}>
			<SvgInstagram />
		</span>
	),
	[SocialLinkType.twitter]: (
		<span className={s.Icons}>
			<SvgTwitter />
		</span>
	),
}
export function FooterSocialIcon(props: { url: string; type: SocialLinkType }) {
	return <a href={props.url}>{socialIcons[props.type]}</a>
}

import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FooterMenuResult } from '../data/content/FooterMenuFragment'
import SvgRespekt from '../svg/sprites/Respekt'
import { FooterAppIcon } from './FooterAppIcon'
import s from './FooterMenu.module.sass'
import { FooterSocialIcon } from './FooterSocialIcon'
import { Link } from './Link'

export type FooterProps = {
	footerMenu: FooterMenuResult
}

export function FooterMenu(props: FooterProps) {
	return (
		<div className={s.FooterMenu}>
			<div className={s.Footer}>
				<div className={s.FooterBox}>
					<p className={s.Title}>{props.footerMenu.online?.title}</p>
					<div className={s.Container}>
						<div className={s.Column}>
							{props.footerMenu.online?.appLinks?.items.map((item, i) => (
								<div key={i} className={s.AppIcon}>
									{item.appLink?.url && item.appLink?.type && (
										<FooterAppIcon url={item.appLink?.url} type={item.appLink?.type} />
									)}
								</div>
							))}
						</div>
						<div className={s.Column}>
							{props.footerMenu.online?.links?.items &&
								props.footerMenu.online.links?.items.map((item) => (
									<div className={s.Link} key={item.id}>
										{item.link && <Link link={item.link}>{item.title}</Link>}
									</div>
								))}
						</div>
					</div>
				</div>
				<div className={s.FooterBox}>
					<p className={s.Title}>{props.footerMenu.links?.title}</p>
					<div className={s.LinkBoxes}>
						{props.footerMenu.links?.links?.items && (
							<div className={s.LinkBox}>
								{props.footerMenu.links?.links?.items.map((item, i) => (
									<div className={s.Link} key={i}>
										{item.link && <Link link={item.link}>{item.title}</Link>}
									</div>
								))}
							</div>
						)}
						{props.footerMenu.linksTwo?.links?.items && (
							<div className={s.LinkBox}>
								{props.footerMenu.linksTwo?.links?.items.map((item, i) => (
									<div className={s.Link} key={i}>
										{item.link && <Link link={item.link}>{item.title}</Link>}
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className={s.FooterBox}>
					<p className={s.Title}>{props.footerMenu.social?.title}</p>
					<div className={clsx(s.Container, s.SocialIcons)}>
						{props.footerMenu.social?.socialLinks?.items.map((item, i) => (
							<div key={i} className={s.SocialIcon}>
								{item.socialLink?.url && item.socialLink?.type && (
									<FooterSocialIcon url={item.socialLink?.url} type={item.socialLink?.type} />
								)}
							</div>
						))}
					</div>
				</div>
				<div className={s.FooterBox}>
					<p className={s.Title}>{props.footerMenu.newsletter?.title}</p>
					<form className={s.Form}>
						<input
							className={s.FormInput}
							type="text"
							placeholder={props.footerMenu.newsletter?.newsletter?.input}
						/>
						<button className={s.FormButton}>
							{props.footerMenu.newsletter?.newsletter?.button}
						</button>
					</form>
				</div>
				<span className={s.Copyright}>
					<RichTextRenderer source={props.footerMenu.copyright ?? null} />
				</span>
			</div>
			<span className={s.Logo}>
				<SvgRespekt />
			</span>
		</div>
	)
}

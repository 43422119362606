/* eslint-disable prettier/prettier */
import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import type { LinkResult } from '../data/content/LinkFragment'

type LinkProps = {
	className?: string
	activeClassName?: string
	gradient?: boolean
	down?: boolean
	nonExternal?: boolean
	children?: React.ReactNode
} & ({ link: LinkResult } | { href: string })

export function Link(props: LinkProps) {
	const activeUrl = useActivePage()

	if ('href' in props) {
		const isActive = activeUrl === props.href
		return (
			<NextLink href={props.href}>
				<a
					className={clsx(props.className, isActive && props.activeClassName)}>
					{props.children}
				</a>
			</NextLink>
		)
	}
	if (props.link?.externalTarget || props.link?.internalTarget) {
		const external = Boolean(props.link.type === LinkType.external)
		const url = (
			props.link.internalTarget?.url ? props.link.internalTarget.url : props.link.externalTarget
		) as string

		const isActive = activeUrl === url
		return (
			<NextLink href={addQueryParametersToUrl(url, props.link.queryParameters)}>
				<a
					className={clsx(props.className, isActive && props.activeClassName)}
					target={external && !props.nonExternal ? '_blank' : ''}>
					{props.children}
				</a>
			</NextLink>
		)
	} else {
		return null
	}
}

function addQueryParametersToUrl(url: string, queryParameters?: string | null) {
	if (queryParameters) {
		// @TODO: řešit jak vypadá queryParameter, a co když už je v url "?"
		return `${url}${queryParameters}`
	}
	return url
}

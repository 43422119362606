/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import { useClickAway } from 'react-use'
import type { HistoryCoverResult } from '../data/content/HistoryCoverFragment'
import SvgHoverClose from '../svg/sprites/HoverClose'
import s from './Annotation.module.sass'
import { PopupsContext } from './Popups'

export type AnnotationProps = {
	annotation: NonNullable<HistoryCoverResult['annotations']>['items'][number]
}

export function Annotation(props: AnnotationProps) {
	const context = React.useContext(PopupsContext)
	const ref = React.useRef<HTMLDivElement | null>(null)
	useClickAway(ref, () => context?.closePopup('click'))

	const annotation = props.annotation
	return (
		<div className={s.Wrapper}>
			<div className={s.Container} ref={ref}>
				<div className={s.Close} onClick={() => context?.closePopup('click')}>
					<SvgHoverClose />
				</div>
				<p className={s.Key}>{annotation.key}</p>
				<div className={clsx(s.Image, s.AnnotationImage)}>
					{annotation.image?.image?.url && (
						<Image
							alt=""
							src={annotation.image.image.url}
							width={annotation.image.image.width ?? 1}
							height={annotation.image.image.height ?? 1}
						/>
					)}
				</div>
				<div className={s.Text}>
					<RichTextRenderer source={annotation.text ?? null} />
				</div>
			</div>
		</div>
	)
}

import * as React from 'react'

function SvgArrowDown() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={74} height={33}>
			<path
				fill="none"
				stroke="#505050"
				strokeLinecap="square"
				strokeMiterlimit={20}
				d="M10 10v0l27 13v0l27-13v0"
			/>
		</svg>
	)
}

export default SvgArrowDown

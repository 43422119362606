import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import type { HistoryCoverResult } from '../data/content/HistoryCoverFragment'
import type { SideMenuHistoryPeriodResult } from '../data/content/SideMenuHistoryPeriodFragment'
import SvgArrowDown from '../svg/sprites/ArrowDown'
import { Annotation } from './Annotation'
import { ContentRenderer } from './ContentRenderer'
import s from './Cover.module.sass'
import { PopupsProvider } from './Popups'
import { SideMenu } from './SideMenu'

export type CoverProps = {
	cover: HistoryCoverResult
	sideMenu?: SideMenuHistoryPeriodResult[]
}

export function countFontSize(title: string) {
	let fontSize = 1
	title = title.replace(/&shy;/gi, '')

	let titleLength = title.length
	if (title.length > 10) {
		titleLength = Math.max(...title.split(' ').map((part) => part.length))
	}
	fontSize = 8 / (titleLength + 1)

	return fontSize
}

export function Cover(props: CoverProps) {
	const popups = React.useMemo(() => {
		const result: Record<string, React.ReactNode> = {}

		props.cover.annotations?.items.forEach((item) => {
			if (item.key) {
				result[item.key] = (
					<div key={item.id} className={s.AnnotationBox}>
						<Annotation annotation={item} />
					</div>
				)
			}
		})

		return result
	}, [props.cover.annotations?.items])

	return (
		<PopupsProvider popups={popups}>
			<div className={s.Cover}>
				<div className={s.CoverAndMenu}>
					{props.sideMenu && (
						<div className={s.SideMenuBox}>
							<SideMenu items={props.sideMenu} />
						</div>
					)}
					<div className={s.CoverBox}>
						<h1
							style={
								{
									'--Cover-fontSize': countFontSize(props.cover.title ?? ''),
								} as React.CSSProperties
							}
							className={s.Title}
							dangerouslySetInnerHTML={{
								__html: props.cover.title ?? '',
							}}></h1>
						<div className={s.Image}>
							{props.cover.coverImage?.image?.url && (
								<Image
									alt=""
									src={props.cover.coverImage.image.url}
									objectFit="cover"
									layout="fill"
								/>
							)}
						</div>
						<div className={clsx(s.Image, s.MobileImage)}>
							{props.cover.mobileImage?.image?.url && (
								<Image
									alt=""
									src={props.cover.mobileImage.image.url}
									objectFit="cover"
									layout="fill"
								/>
							)}
						</div>
						<div className={s.ArrowBox}>
							<a className={s.ArrowLink} href="#main">
								<p className={s.ArrowText}>Dolů</p>
								<span className={s.Arrow}>
									<SvgArrowDown />
								</span>
							</a>
						</div>
					</div>
				</div>

				<div className={s.LeadParagraphWrapper}>
					<span className={s.LeadParagraph}>
						<div>
							{props.cover.leadContent && (
								<ContentRenderer content={props.cover.leadContent} omitStyles />
							)}
						</div>

						<div className={s.Boxes}>
							{/* Array Number related to Cover.module.sass */}
							{Array(20)
								.fill(null)
								.map((_, index) => (
									<div key={index}></div>
								))}
						</div>
					</span>
				</div>

				<span id="main"></span>
			</div>
		</PopupsProvider>
	)
}

import * as React from 'react'

function SvgHamburger() {
	return (
		<svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
			<g fill="#FFFDFA" fillRule="evenodd">
				<rect y={14} width={16} height={2} rx={1} />
				<rect width={16} height={2} rx={1} />
				<rect y={7} width={16} height={2} rx={1} />
			</g>
		</svg>
	)
}

export default SvgHamburger

import * as React from 'react'

function SvgClose() {
	return (
		<svg width={18} height={18} xmlns="http://www.w3.org/2000/svg">
			<g fill="#FFFDFA" fillRule="evenodd">
				<path d="M1.222 15.364L15.364 1.222a1 1 0 011.414 1.414L2.636 16.778a1 1 0 11-1.414-1.414z" />
				<path d="M2.636 1.222l14.142 14.142a1 1 0 01-1.414 1.414L1.222 2.636a1 1 0 111.414-1.414z" />
			</g>
		</svg>
	)
}

export default SvgClose

import * as React from 'react'

function SvgTwitter() {
	return (
		<svg viewBox="0 0 39 41" xmlns="http://www.w3.org/2000/svg">
			<g fill="none">
				<rect width={39} height={40} rx={3} fill="#62C1EA" transform="translate(0 .9)" />
				<path
					d="M28.084 15.379a6.77 6.77 0 01-2.016.575 3.643 3.643 0 001.544-2.02 6.853 6.853 0 01-2.229.886 3.444 3.444 0 00-2.562-1.154c-1.939 0-3.51 1.636-3.51 3.652 0 .286.031.565.091.832-2.917-.152-5.504-1.606-7.235-3.816a3.752 3.752 0 00-.475 1.836c0 1.267.62 2.385 1.561 3.04a3.39 3.39 0 01-1.59-.457v.046c0 1.769 1.21 3.246 2.816 3.581a3.404 3.404 0 01-1.586.062c.447 1.451 1.743 2.507 3.279 2.537a6.866 6.866 0 01-5.196 1.513 9.662 9.662 0 005.381 1.641c6.456 0 9.986-5.566 9.986-10.392l-.01-.473a7.278 7.278 0 001.751-1.891"
					fill="#fff"
				/>
			</g>
		</svg>
	)
}

export default SvgTwitter

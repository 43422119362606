import Image from 'next/image'
import { AppLinkType } from '../../generated/content'
import amazon from '../assets/Amazon.png'
import appstore from '../assets/AppStore.png'
import googlePlay from '../assets/GooglePlay.png'
import s from './FooterAppIcon.module.sass'

const appIcons = {
	[AppLinkType.AppStore]: (
		<span className={s.Icons}>
			<Image src={appstore} alt="" />
		</span>
	),
	[AppLinkType.GooglePlay]: (
		<span className={s.Icons}>
			<Image src={googlePlay} alt="" />
		</span>
	),
	[AppLinkType.Amazon]: (
		<span className={s.Icons}>
			<Image src={amazon} alt="" />
		</span>
	),
}
export function FooterAppIcon(props: { url: string; type: AppLinkType }) {
	return <a href={props.url}>{appIcons[props.type]}</a>
}
